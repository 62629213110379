/**
 * livechat.js
 */

(function() {
    const appendScriptToBody = function(src) {
      var script = document.createElement("script");
      script.src = src;
      script.id = 'livechat-app-js';
      document.body.appendChild(script);
    }

    const appendStyleToHead = function(src) {
      const link = document.createElement("link");
      link.href = src;
      link.rel = 'stylesheet';
      link.id = 'livechat-app-css';
      document.head.appendChild(link);
    }

    window.liveChat = function(params) {
      window._liveChatConfig = {
        id: params.id,
      };

      if (params?.container) {
        if (!document.getElementById(params.container)) {
          throw new Error('Livechat container does not exist');
        } else {
          window._liveChatConfig.container = params?.container
        }
      } else {
        window._liveChatConfig.container = 'livechat-app'

        const div = document.createElement("div");
        div.id = 'livechat-app';
        document.body.appendChild(div);
      }

      if (params?.config) {
        window._liveChatConfig.config = params?.config;
      }

      // process.env.APP_VERSION is injected by webpack
      // const host = process.env.APP_HOST + '/' + process.env.APP_VERSION
      const host = process.env.APP_HOST

      if (!document.getElementById('livechat-app-css')) {
        appendStyleToHead(host + '/app.css');
      }

      if (!document.getElementById('livechat-app-js')) {
        appendScriptToBody(host + '/app.js');
      }
    };
})();
